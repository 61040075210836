<template>
	<div class="event-detail">
		<EventDetail />
	</div>
</template>

<script>
// @ is an alias to /src
import EventDetail from '@/components/EventDetail.vue';

export default {
	name: 'detail',
	components: {
		EventDetail,
	},
};
</script>
