







































































































































































































import Vue from 'vue';
import { mapState } from 'vuex';
import env from '../../../server/env';
import EventDetailCourses from '@/components/EventDetailCourses.vue';
import Header from '@/components/Header.vue';
import marked from 'marked';

export default Vue.extend({
	name: 'EventDetail',
	components: {
		Header,
		EventDetailCourses,
	},
	data: () => ({
		headers: [
			{
				text: 'Name',
				align: 'start',
				sortable: false,
				value: 'name',
			},
			{ text: 'Typ', value: 'type' },
			{ text: 'Aktion', value: 'actions', sortable: false },
		],
		targetGroups: env.targetGroups,
		statesDe: env.states.filter((state) => state.id.slice(0, 2) === 'DE'),
		statesAt: env.states.filter((state) => state.id.slice(0, 2) === 'AT'),
		statesCh: env.states.filter((state) => state.id.slice(0, 2) === 'CH'),
	}),
	computed: {
		topicsTransformed() {
			return marked(this.eventDetail.topics ?? '');
		},
		setupTransformed() {
			return marked(this.eventDetail.setup ?? '');
		},
		othersTransformed() {
			return marked(this.eventDetail.others ?? '');
		},
		accessRequirementsTransformed() {
			return marked(this.eventDetail.accessRequirements ?? '');
		},
		reductionTransformed() {
			return marked(this.eventDetail.reduction ?? '');
		},
		additionalCostsTransformed() {
			return marked(this.eventDetail.additionalCosts ?? '');
		},
		isNewEvent() {
			return this.$route.params.eventId === 'new';
		},
		errorMessage() {
			const text = '';

			const errors = [];

			if (this.eventDetail.name === '') {
				errors.push('Name');
			}

			if (this.eventDetail.states.length === 0) {
				errors.push('Bundesländer');
			}

			if (this.eventDetail.targetGroups && this.eventDetail.targetGroups.length === 0) {
				errors.push('Zielgruppe');
			}

			if (errors.length === 0) {
				return null;
			} else {
				return 'Bitte bearbeiten Sie noch folgende Felder: ' + errors.join(', ') + '<br>Danach können sie speichern.';
			}
		},
		...mapState(['isLoading', 'eventDetail', 'organizer']),
	},
	watch: {
		eventDetail: {
			handler: function(newVal, oldVal) {
				this.$store.dispatch('updateEventDetail', newVal);
			},
			deep: true,
		},
	},
	mounted() {
		this.$store.dispatch('loadOrganizer', this.$route.params.organizerId);
		this.$store.dispatch('loadEvent', this.isNewEvent ? 'receEpn9hJZtcdSTH' : this.$route.params.eventId);
	},
	methods: {
		saveEventDetail() {
			this.$store.dispatch('saveEventDetail', this.$route.params.eventId !== 'new');
		},
	},
});
