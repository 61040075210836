












































































import Vue from 'vue';
import { mapState } from 'vuex';
// @ts-ignore
export default Vue.extend({
	name: 'EventDetailCourse',

	data: () => ({
		dialog: false,
		dialogDelete: false,
		headers: [
			{
				text: 'Datum',
				align: 'start',
				sortable: false,
				value: 'date',
			},
			{ text: 'Ort', value: 'location' },
			{ text: 'Aktion', value: 'actions', sortable: false },
		],
		editedIndex: -1,
		editedItem: {
			name: '',
			type: 0,
		},
		defaultItem: {
			name: '',
			type: 0,
		},
	}),

	computed: {
		formTitle() {
			return this.editedIndex === -1 ? 'Neue Kurszeit' : 'Kurszeit editieren';
		},
		isNewEvent() {
			return this.$route.params.eventId === 'new';
		},
		...mapState(['eventDetail']),
	},

	watch: {
		dialog(val) {
			// @ts-ignore
			val || this.close();
		},
		dialogDelete(val) {
			// @ts-ignore
			val || this.closeDelete();
		},
	},
	methods: {
		// @ts-ignore
		editItem(item) {
			this.editedIndex = this.eventDetail.courseDates.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialog = true;
		},
		// @ts-ignore
		deleteItem(item) {
			this.editedIndex = this.eventDetail.courseDates.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogDelete = true;
		},

		deleteItemConfirm() {
            // @ts-ignore
			this.$store.dispatch('deleteCourse', this.editedItem.id);
            // this.eventDetail.courseDates.splice(this.editedIndex, 1);
            // @ts-ignore
			this.closeDelete();
		},

		close() {
			this.dialog = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		closeDelete() {
			this.dialogDelete = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		save() {
			if (this.editedIndex > -1) {
                Object.assign(this.eventDetail.courseDates[this.editedIndex], this.editedItem);
                // @ts-ignore
				this.$store.dispatch('updateCourse', this.editedItem.id);
			} else {
                this.eventDetail.courseDates.push(this.editedItem);
                // @ts-ignore
				this.$store.dispatch('saveCourse', this.editedItem.id);
            }
            // @ts-ignore
			this.close();
		},
	},
});
